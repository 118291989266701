
.sidebar {
    /* background: rgb(0, 7, 61); */
    background: #FBFBFB;
    color: #484848;
    height: 100vh;
    overflow-y: auto;
  }
  
  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
  }
  .logo {
    font-size: 18px;
    line-height: 0;
  }
  .bars {
    width: 30px;
  }
  .hide {
    display: none;
  }
  
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
    height: 30px;
    padding: 10px;
  }
  .search input {
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    background: rgb(238, 238, 238);
  
    color: #484848;
  }
  
  .routes {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .link {
    display: flex;
    text-decoration: none;
    color:"black";
    gap: 20px;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link:hover {
    border-right: 4px solid white;
    /* background: rgb(45, 51, 89); */
    background: #F4F4FC;
  
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .active {
    border-right: 4px solid white;
    /* background: rgb(45, 51, 89); */
    background: #F4F4FC;
  
  }
  .link_text {
    white-space: nowrap;
    font-size: 15px;
    color:#484848;
    text-decoration: none;
  }
  
  .menu {
    display: flex;
    /* color: white; */
    color: #484848;
  
  
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
  }
  .menu_item {
    display: flex;
    gap: 10px;
  }
  .menu_container {
    display: flex;
    flex-direction: column;
  }
  .menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
  }
  .outlet{
    width:calc(100%-350px);
    height: calc(100%-50px);
  }
  .pagesoutlet{
    width:100%;
    height: 100%;
    background: #fff;
    margin: 7px;
  
  }
  .pageLayout{
    margin-top: 70px;
  
  }
  .active-toggle {
    background-color: #28a745; /* Set your desired background color for the active toggle */
  }
  .card {
    border: 1px solid #ccc; /* Default border style */
    transition: border-color 0.3s ease; /* Adding a smooth transition effect */
  }
  
  .card:hover {
    border-color:rgb(24, 63, 145); /* New border color on hover */
    /* You can also modify other properties like border-width here */
  }
  
.ContainerMain{
    position: fixed;
 }
 .LeftSideContainer{
     display: flex;
     height: 100vh;
 }
 .RightSideContainer{
     background: url('../../assets/loginImage.png') center center no-repeat;
     background-size: cover;
     height: 100vh;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }
 .cardmain.mt-2.card {
     height: auto;
     border:1px solid #8297bf;
     border-radius: 10px;
     background-color: #a9bad9;
     padding:30px;
     width:60%;
 }
 .cardBody{
     width:100%;
     margin:35px;
 }
 .inputs{
     margin:5px;
     width: 100%;
 }
 .inputField{
     margin-top: 16px;

 }
button.loginButton {
  margin-top: 16px;
  padding: 10px;
  width: 400px;
  background-color: #183f91;
  color: white;
  border: none;
  border-radius: 3px;
  outline: none;
}

 .loginHeadding{
 font-family: 'Open Sans';
 font-style: normal;
 font-weight: 700;
 font-size: 24px;
 color:white;
 /* line-height: 65px; */
 }
 .login .left .subHeading{
  color:#656565;
  font-weight: 600;
}
 label.form-label{
 color: #000000 !important;
 font-size: 14px !important;
 margin-bottom:4px !important;
 font-weight: 600 !important;
}
 @media (max-width: 767px) {

     .LeftSideContainer {
       display: none;
     }
   }

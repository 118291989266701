.assignBtn{
   background: green;
   /* color: white; */
 }

.side-drawer { 
    height: 88%;
    /* height: 822px; */

    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 40%;
    z-index: 999;
    /* box-shadow: 1px 0px 7px rgba(0,0,0,0.5);  */
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    display: flex;
    justify-items: center;
    margin-top: 70px;
    background: #FAFAFE;
    border: 5px solid #F4F4FC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    color: var(--gray-500, #667085);




    border-radius: 10px 0px 0px 10px;


 }
 .side-drawer.open {
    transform: translateX(0);
 }
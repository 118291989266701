* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,body{
  padding:0;
  margin:0;
}

.primaryBtn{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 9px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color:#183F91 !important;
  border:1px solid #183F91 !important;
  background: #ffffff !important;
}
.primaryBtn:hover{
  color:#ffffff !important;
  border:1px solid transparent !important;
  background: #183F91 !important;
}

.secondaryBtn{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 9px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color:#EE5454 !important;
  border:1px solid #EE5454 !important;
  background: #ffffff !important;
}
.secondaryBtn:hover{
  color:#ffffff !important;
  border:1px solid transparent !important;
  background:#EE5454 !important;
}

.choosefile{
  color: white !important;
}

.assiBtn{
  background : green !important;
}

.main-container {
  display: flex;
  width: 100vw;
}
main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}


button {
  margin: 0.2rem;
  padding: 0.3rem 0.6rem;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

button.active {
  background-color: #007bff;
  color: #ffffff;
}
/* logout button */
button.logoutBtn{
  background-color: #183F91;

}

/* table design */
 @media (max-width: 453px) {
.outer-wrapper {
  margin: 10px !important;
  padding: 10px !important;
  background: #FFFFFF !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2) !important;
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.table-wrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  height: fit-content;
  max-height: 66.4vh;
  margin-top: 5px;
  margin: 15px;
  padding-bottom: 20px;
}

.table-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 0px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border: 3px solid var(--scrollbarBG);
}

   }



/* pagination */
.pagination{
  width: 100% !important;
}

.pagination .prev,
.pagination .prev:focus,
.pagination .next,
.pagination .next:focus{
  background: transparent !important;
  border: none !important;
  color: var(--FoxBlack) !important;
  transition: 0.3s !important;
}

.pagination .prev:hover{
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(-5px);
} 

.pagination .next:hover{
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(5px);
} 

.pagination .activePageButton{
  border: none;
  color: #000000 !important;
  background: #ffffff !important;
  transition: 0.1s !important;
  font-weight: 600;
}

.pagination .pageButton{
  border: 1px solid transparent !important;
  color: var(--FoxBlack) !important;
  background: transparent !important;
  transition: 0.1s !important;
}

.pagination select.form-select{
  border: 1px solid #6f6f6f;
  transition: 0.3s;
  
}

@media(min-width:768px){
  .mapView{
    width: 67% !important;
    height: 100% !important;
  }

}

@media(max-width:768px){
  .mapView{
    width: 100% !important;
    height: 70% !important;
  }
    .cardContainer{
      margin-top:120% ;
  }
}

.mapcontainer{
  display: block;
  border:1px solid #ffffff;
  box-shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.text{
  color:#667085
}
.cardContainer{
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.cardBox{
  width:100%  !important;
  max-width: 325px  !important;
  background-color:#F9F9F9 !important;
  border-radius: 9px  !important;
  margin: 10px auto  !important;
  border: unset  !important;
}
.cardBody{
  padding-top: 0!important;
  margin: 0 !important;
}
.cardBody p{
  margin:0px;
  padding:0px;
}
.verifiedBtn.w-25.btn.btn-primary {
  width:50%;
  border-radius: 9px;
  font-size: 18px;
  font-weight: 600;
  color:#ffffff;
  border:1px solid #54A832;
  background-color: #54A832;
}

.form-label {
    margin-bottom: 0rem;
    color: black;
    font-weight: 500;
}


.low{
  padding: 0px 24px; 
  color: #FFC530;
  background-color: #FFFAEC; 
  margin-left: 12px;
  border-radius: 16px;
}
.low-icon{
  font-size:36px; 
  color: #FFC530;
}
.medium{
  padding: 0px 24px; 
  color: green;
  background-color:#ECFDF3; 
  margin-left: 12px;
  border-radius: 16px;
}
.medium-icon{
   font-size:36px; 
   color: green;
}
.high{
  padding: 0px 24px; 
  color: red;
  background-color: #FFEFEF; 
  margin-left: 12px;
  border-radius: 16px;
}
.high-icon{
   font-size:36px; 
   color: red;
}

.sidenav-lg::-webkit-scrollbar {
    width: 8px;
  }
  .sidenav-lg::-webkit-scrollbar-track {
    background: var(--FoxBlack);
  }
  .sidenav-lg::-webkit-scrollbar-thumb {
    background-color: var(--FoxYellow);
    border-radius: 6px;
  }

  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(251, 251, 251, 0.2) 0px 8px 24px;
    background: #FBFBFB;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-right: 8px;
    overflow-y: scroll;
    width: 40vh;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    --scrollbarBG: rgb(0, 0, 0);
    --thumbBG: #004686;
  }

  .sidenav-lg hr{
    border-width: 2px;
    color: #183F91;
    width: 100%;
    margin: auto;
    opacity: 1;
  }

  .sidenav-lg-parent {
    position: relative;
  }
 
  .outletDiv {
    position: absolute;
    left: 41vh;
    right: 2vh;
    top: 0vh;
  }

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 1vh;
    right: 1vh;
    top: 0vh;
  }

  nav{
    left: 0;
    right: 0;
  }
}
.accordionNav{
  border: 1px solid var(--FoxBlack) !important;
  color: var(--FoxBlack) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  background: transparent !important;
}

.accordionNavActive{
  border: 1px solid transparent !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  background: var(--FoxBlack) !important;
}

.accordion{
  background-color: transparent !important;
}

.accordion .accordion-item{
  border: #FFFFFF;
}

.accordion .accrodion-body {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion-item .accordion-header {
  background: #FBFBFB !important;
  border: none !important;
  padding: 0px;
  box-shadow: none;
  color: #000000 !important;
  border: none !important;
}

.accordion .activeMenu .accordion-button {
  color: #ffffff !important;
  background: #183F91!important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: 600 !important;
}

.accordion .dropDown{
  border-radius: 0px 0px 12px 0px !important;
  background: var(--FoxYellow);
}

.accordion .activeMenu.dropDown .accordion-button{
  border-radius: 0px 12px 0px 0px !important;
}

.accordion .accordion-button {
  background: transparent !important;
  color: #484848 !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 0px 12px 12px 0px !important;
  box-shadow: none !important;
  padding: 15px 35px;
}

.accordion .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}


.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: #000000 !important;
  border: none !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button.collapsed:after {
  box-shadow: none;
  outline: #000000 !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion  label:nth-child(1){
  color: rgb(222, 219, 219) !important;
  padding: 1px 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0px 0px 0px 0px !important;

}

.accordion  label{
  color: rgb(222, 219, 219) !important;
  padding: 1px 20px !important;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px 0px 0px;

}

.modal .modal-content {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  background: #FAFAFE;
}

.modal .modal-content .modal-header{
  background: var(--FoxYellow);
  padding: 10px 16px;
  font-weight: 600;
}

.modal .modal-content .modal-header h5{
  margin: 0px;
  font-weight: 600;
  color: var(--FoxBlack);
}

.modal label{
  font-size: 15px !important;
  color: #575757;
  font-weight: 600 !important;
  margin: 0px 0px 4px !important;
}

.modal .modal-footer{
  background: #FAFAFE !important;
}

.p-margin p{
  margin-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


nav {
  position: fixed;
  left: 37vh;
  right: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

nav h5 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

@media (max-width: 992px) {
  .lg-nav {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 0%;
    z-index: 1000;
    flex-direction: column;
     padding: 8% 0% 20% 0px;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: #FFFFFF;
  }

  .mobile-nav li {
    margin-bottom: 15px;
    color: yellow;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav a.nav-link{
    border: 1px solid var(--FoxBlack);
    color: var(--FoxBlack);
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 15px;
    font-weight: 500;
 }
 
  .mobile-nav a.nav-link.active{
   background: var(--FoxBlack);
   border: 1px solid transparent;
   color: #ffffff;
   font-weight: 500;
 }

  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    margin:5px 0px 5px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: #183F91;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: #183F91;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }

  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

}


@media (min-width: 992px) {
  .lg-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PopupData div{
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-top: 5px;
  }

  .PopupData div p{
    margin-bottom: 0px;
  }

.mobile-nav {
    display: none;
  }

.mobile-nav-toggle {
    display: none;
  }

  .sidenav-lg::-webkit-scrollbar {
    width: 8px;
  }
  .sidenav-lg::-webkit-scrollbar-track {
    background: var(--FoxBlack);
  }
  .sidenav-lg::-webkit-scrollbar-thumb {
    background-color: var(--FoxYellow);
    border-radius: 6px;
  }

  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-right: 8px;
    overflow-y: scroll;
    width: 36vh;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    --scrollbarBG: rgb(0, 0, 0);
    --thumbBG: #004686;
  }

  .sidenav-lg hr{
    border-width: 2px;
    color: #F7CE40;
    width: 40%;
    margin: auto;
    opacity: 1;
  }

  .sidenav-lg-parent {
    position: relative;
  }
 
  .outletDiv {
    position: absolute;
    left: 37vh;
    right: 1vh;
    top: 0vh;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 1vh;
    right: 1vh;
    top: 0vh;
  }

  nav{
    left: 0;
    right: 0;
  }
}
.main-div{
  width:260px;
  height:150px;
  position:relative;
  background:linear-gradient(to right, #183f91, #054eb3);
  border-radius:10px;
  transition: transform 0.2s;
}
.main-div:hover {
  transform: scale(1.1);
  z-index: 1;
}

.cardContent{
  display:flex;
  flex-direction:column;
  padding:20px
}

.cardHeading{
  color:white
}

.cordContent{
  color:white;
  font-weight:800
}

.cardIcon {
  color: white;
  font-weight: bold;
}

.cardIcon:hover {
  transform: scale(1.2);
  cursor: pointer; 
}

.cardImg{
  position:absolute;
  right:0;
  bottom:0
}

.cardsmallimg{
  width:80px
}

.boxValue{
  text-align: center; 
  color: #183F91;
  font-size: 30px;
  font-weight: 800; 
  margin: 0; 
  letter-spacing:1
} 

/* for login */
.login{
  height: 100vh;
  width: 100vw;
}
.login .parent{
  height: 100vh;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
} 
.errMsg{
  color: red !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin: 3px 0px 0px 5px !important;
  text-align: start !important;
}

/* design for police list */
button.policelist{
 color: #183F91;
 border:1px solid #183F91;
 font-weight: 500; 
}
button.policelist:hover{
  background-color: #183F91;
  color: #FFFFFF;
  border:1px solid #183F91
}

/* carousal css */
.corousalImg {
  margin: 20px;
}

.cyberImages {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.img-div {
  position: relative;
}

.downldIcn {
  position: absolute;
  bottom: 3px;
  right: 38px;
  opacity: 0;
  transition: opacity 0.3s;
  font-weight: bold;
}

.img-div:hover .downldIcn {
  opacity: 1;
}

.img-div:hover img {
  opacity: 0.4;
}